var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "float-right ",
      staticStyle: { cursor: "pointer", "font-size": ".85rem" },
      on: {
        click: function($event) {
          return _vm.sort(_vm.index, _vm.column.key)
        }
      }
    },
    [
      _vm._v(" " + _vm._s(_vm.column.title) + " "),
      _c("span", { staticClass: "bee-table-sort" }, [
        _c("i", {
          staticClass: "bee-sys-icon md-arrow-dropup",
          class: { on: _vm.column.orderBy == "asc" },
          attrs: { id: _vm.column.key + "_asc" }
        }),
        _c("i", {
          staticClass: "bee-sys-icon md-arrow-dropdown",
          class: { on: _vm.column.orderBy == "desc" },
          attrs: { id: _vm.column.key + "_desc" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }