export default {
    props: {
        hasSearchBar: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data () {
        return {
            // perPage:12,
            searchBarPlaceHolder:"ユーザー名、メールアドレスで検索",
            pages:0,
            checkedList:[],
            perPageList:[10,20,30/*,100,200*/],
            windowWidth:0,
            windowHeight:0,
            headerWidth:0,
            headerHeight:0,
            formCardHeight:0,
            pagingParams: {keyword: '', columnFilters: {}, sort: [], currPage: 1, perPage: 10},
            isLoading: false,
            rows:[],
            _columnList:[],
            context:{},
            isCollapsed: true,
            searchInit:true
        }
    },
    beforeDestroy(){
        // console.log("====this.beforeDestroy");
      // this.$destroy();
    },
    mounted() {
        window.removeEventListener('resize', this.handleResize);
        window.addEventListener('resize', this.handleResize);
        this.makeColumnList();
        if(this.searchInit && this.goodPaging){
            this.goodPaging();
        }
        let thiz = this;
        setTimeout(function(){
            thiz.handleResize();
        },300)

    },
    methods:{
        makeColumnList(){
            let columnList = [];
            if(this.columns){
                //変更される回数は1または最大2回
                for (let i = 0; i < this.columns.length; i++) {
                    columnList.push(this.columns[i].key);
                    if(this.columns[i].children){
                        for (let j = 0; j < this.columns[i].children.length; j++) {
                            columnList.push(this.columns[i].children[j].key);
                        }
                    }
                }
                this._columnList = columnList;
            }
        },
        getCheckedList(){
            this.checkedList = this.$refs.bee.getSelection();
        },
        sort(index,key){
            /*console.log("sort",JSON.stringify({index,key}));


            // var orderBy = this.columns[index].orderBy;
            // if(orderBy == '' || orderBy == "asc"){
            //     orderBy = "desc";
            // }else {
            //     orderBy = "asc"
            // }
            var orderBy = "";
            //他のものの全ソートiconを解除し、自分のもののソートiconを変更する
            //変更される回数は1または最大2回
            for (let i = 0; i < this.columns.length; i++) {
                if (this.columns[i].key == key ) {
                    if (this.columns[i].orderBy == '' || this.columns[i].orderBy == "asc") {
                        orderBy = "desc";
                    } else {
                        orderBy = "asc"
                    }
                    this.columns[i] = Object.assign(this.columns[i], {orderBy: orderBy});
                } else if (this.columns[i].key != key && this.columns[i].orderBy) {
                    this.columns[i] = Object.assign(this.columns[i], {orderBy: ""});
                }

                if(this.columns[i].children){
                    for (let j = 0; j < this.columns[i].children.length; j++) {
                        if (this.columns[i].children[j].key == key) {
                            if (this.columns[i].children[j].orderBy == '' || this.columns[i].children[j].orderBy == "asc") {
                                orderBy = "desc";
                            } else {
                                orderBy = "asc"
                            }
                            this.columns[i].children[j] = Object.assign(this.columns[i].children[j], {orderBy: orderBy});
                        } else if (this.columns[i].children[j].key != key && this.columns[i].children[j].orderBy) {
                            this.columns[i].children[j] = Object.assign(this.columns[i].children[j], {orderBy: ""});
                        }
                    }
                }
            }*/
            // this.goodPaging();
            let orderBy = "";
            //他のものの全ソートiconを解除し、自分のもののソートiconを変更する
            for (let i = 0; i < this._columnList.length; i++) {
                if(key == this._columnList[i]){
                    if (document.querySelector("#" + key + "_asc").classList.contains('on')) {
                        document.querySelector("#" + key + "_asc").classList.remove("on");
                        orderBy = "desc";
                    } else {
                        document.querySelector("#" + key + "_desc").classList.remove("on");
                        orderBy = "asc";
                    }
                    document.querySelector("#" + key + "_" + orderBy).classList.remove("on");
                    document.querySelector("#" + key + "_" + orderBy).classList.remove("on");
                    document.querySelector("#" + key + "_" + orderBy).classList.add("on");
                    continue;
                }
                if(document.querySelector("#" + this._columnList[i] + "_asc")){
                    document.querySelector("#" + this._columnList[i] + "_asc").classList.remove("on");
                }
                if(document.querySelector("#" + this._columnList[i] + "_desc")){
                    document.querySelector("#" + this._columnList[i] + "_desc").classList.remove("on");
                }
            }
            this.pagingParams.sort=[{field: key, sort: orderBy}];
            //検索を行う
            this.goodPaging();
        },
        hiddenColumn(key){
            //this.columns = columns後に実行すること

            // this.columns.splice(0, 1);
            // chidrenは対応しないため、削除される可能性があるカラムは1段落のカラムにすること
            for (let i = 0; i < this.columns.length; i++) {
                if (this.columns[i].key == key) {
                    this.columns.splice(i, 1);
                    break;
                }
            }

            // this.columns[this.findColumnIndex(this.columns,'hospitalName')].hidden = this.$store.state.user.role == 'DOCTOR';
        },
        changePage(pageNumber){
            // this.$emit(this.pagingParams, {currPage:pageNumber})
            // Object.assign(this.pagingParams,{currPage:pageNumber});
            this.goodPaging(true);
        },topLeft(id){
            if(id == undefined){
                id="";
            }
            //スクロールトップと左に移動
            document.querySelector( `${id} .bee-table-header`).scrollLeft = 0;
            let body = document.querySelector(`${id} .bee-table-body`);
            body.scrollLeft = 0;
            body.scrollTop = 0;
        },customSpan({ row, column, rowIndex, columnIndex }){
            // console.log("row",row,"column",column,"rowIndex",rowIndex, "columnIndex",columnIndex);
            if(column.key == "age1"){
                return {
                    rowspan: 1,
                    colspan: 1,
                };
            }
            return {rowspan: 1,colspan: 1};
        },devSearch(){
            //開発環境の場合のみ検索する
            // if(process.env.VUE_APP_MODE == 'dev' ){
                this.goodPaging();
            // }

        },handleResize(){
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            this.formCardHeight = 0;
            this.headerHeight = 0;
            let formCard = document.querySelector(".form-card")
            if(formCard){
                this.formCardHeight =formCard.clientHeight;
            }
            let theHeader = document.querySelector(".TheHeader")
            if(theHeader){
                this.headerHeight = theHeader.clientHeight;
            }
        },
        clickable(){
            return "clickable";
        },
        isRole(roleName){
            if(Array.isArray(roleName)){
                return roleName.indexOf(this.$store.state.user.role) >= 0;
            }
            return this.$store.state.user.role == roleName;
        },
        isAdministrator(){
            return this.isRole('ADMINISTRATOR');
        },
        isRegistrator(){
            return this.isRole('REGISTRATOR');
        },isMaintenance(){
            return this.isRole('MAINTENANCE');
        },isProvider(){
            return this.isRole('PROVIDER');
        },isDoctor(){
            return this.isRole('DOCTOR');
        },isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            debugger;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
        ,isFloat: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if(charCode == 46){
                return;
            }
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
        ,isDecimal:function isDecimal(value){
            return /^([1-9]\d*|0)$/.test(value)
        }
    },
    computed: {
        gridCardHeight() {
            return this.windowHeight - this.headerHeight - this.formCardHeight - 100;
        },
    },watch:{
        perPage(){
            this.pagingParams.perPage = this.perPage;
            this.goodPaging();
        },
        // isCollapsed:function(){
        //     this.maxHeight = this.isCollapsed ? this.closeHeight : this.openHeight;
        // },
    }
}