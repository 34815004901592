<template>
  <div class="float-right " @click="sort(index,column.key)" style="cursor: pointer;font-size: .85rem" >
    {{ column.title }}
    <span class="bee-table-sort">
        <i class="bee-sys-icon md-arrow-dropup" :id="column.key+'_asc'" v-bind:class="{'on':column.orderBy == 'asc' }"></i>
        <i class="bee-sys-icon md-arrow-dropdown" :id="column.key+'_desc'" v-bind:class="{'on':column.orderBy == 'desc'}"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "MyBeeHeader",
  props:{
    column:Object,
    index:Number
  },methods:{
    sort(index,key){
      this.$emit("sort",index,key);
    }
  }
}
</script>