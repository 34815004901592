<template>
  <CRow style="margin-left: 1px;margin-right:0px;" class="align-middle py-2"><!--background-color: #0a3e0a; -->
    <CCol col="12" class="align-middle">
      <div class="input-group" style="height: 45px;" ><!-- style="border-bottom:#0f510f solid 4px"-->
        <div class="input-group-prepend" style="vertical-align: middle">
          <select autocomplete="off" id="perPage" name="perPageSelect" class="ml-1 form-control" v-model="perPageV"
                  style="display: inline-block;padding-top:1px;padding-bottom:1px;margin-top: 4px;">
            <option :value="item" v-for="item in perPageList" :key="item">{{ item }}件</option>
          </select>
          <CButton color="warning" size="sm" class="m-1" @click="goodPaging">検索</CButton>
<!--          style="height:38px;"-->
        </div>
<!--        <input type="text" :value="keyword" @input="$emit('update:keyword', $event.target.value)" :placeholder="searchBarPlaceHolder"-->
<!--               class="form-control border-0 m-1 mr-2" @keypress.enter.prevent="devSearch"-->
<!--               style="background-color: #0f510f;color:white;">-->

        <input type="text" :value="keyword" @input="$emit('update:keyword', $event.target.value)" :placeholder="searchBarPlaceHolder"
               class="form-control m-1 mr-2" style="border:1px solid #878787" @keypress.enter.prevent="devSearch">


        <div class="input-group-append my-auto">
          <slot name="tools"></slot>
                <CPagination  :active-page="currPageV" :pages="pages" :dots="false" :limit="2"
                       @update:activePage="changePage"
                       style="display: inline-block;line-height: 5" class="p-1 my-auto"/>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "MyBeeSearchBar",
  props:{
    searchBarPlaceHolder:String,
    perPage:Number,
    currPage:Number,
    pages:Number,
    perPageList:Array,
    keyword:String
  },
  data(){
    return {
      currPageV:this.currPage,
      keywordV:this.keyword,
      perPageV:this.perPage,
    }
  },methods:{
    goodPaging(){
      this.$emit('goodPaging');
    },
    changePage(pageNumber){
      this.currPageV = pageNumber;
      this.$emit('update:currPage', this.currPageV)
      this.$emit("changePage");
    },devSearch(){
      this.$emit('devSearch')
    }
  },
  watch:{
    searchBarPlaceHolder(){
      this.$emit('update:searchBarPlaceHolder', this.searchBarPlaceHolder)
    },
    perPageV(){
      this.$emit('update:perPage', this.perPageV);
    },
    currPage(){
       this.currPageV = this.currPage;
    },
    // keywordV(){
    //   this.$emit('update:keyword', this.keywordV);
    // },perPageV(){
    //   this.$emit('update:perPage', this.perPageV);
    // }
    keyword(){
      this.$emit('update:keyword', this.keyword);
    },perPage(){
      this.$emit('update:perPage', this.perPage);
    }
  },
}
</script>

<style>
/*bee*/
.v-table-row > span{
  font-size: 10px;
}

/*th[class^="bee-table-column-"]{*/
/*  background-color: #0a3e0a;*/
/*  border-top:0;*/
/*  border-bottom:0;*/
/*}*/
MyBeeSearchBar
/*th > .bee-table-cell{*/
/*  color:lightgray;*/
/*}*/
/*.bee-table-wrapper{*/
/*  border-top:0px;*/
/*}*/
/*.bee-table-cell span{*/
/*  font-weight:bold;*/
/*  font-size: 1rem;*/
/*}*/
/*.bee-table-tip span{*/
/*  color:black;*/
/*}*/

li.page-item.disabled{
/*display:none;*/
/* visibility: hidden;*/
}
/* bee end */
</style>